.credits-container{

  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  .building-elevation{
    max-width: 1024px;
    min-width: 640px;
  }
  p{
    font-size: 1.5rem;
    padding: 0;
    text-align: center;
    &.title{
      font-size: 1.7rem;
      font-weight: bold;
    }
  }
  .logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 100%;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}