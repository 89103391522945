.overlay-container{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: white;
  padding:0 33px;
  box-sizing: border-box;
  overflow: scroll;
  .overlay-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 99px;
    z-index: 999;
    background-color: white;
    margin: 0;
    padding: 0 33px;
    box-sizing: border-box;
    font-family: "raleway", sans-serif;
    font-weight: 700;
    font-style: normal;
    background-size: cover;
    background-position: center;
    color: white;
    .close-overlay{
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.2rem;
    }
  }
  .overlay{
    position: relative;
    width: 100%;
    height: calc(100% + 99px);
    padding: 99px 0;
    margin-bottom: 33px;
    font-family: "futura-pt", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem;
    display: flex;
    border-box: box-sizing;
    justify-content: center;
    .overlay-content{
      margin: 33px 0;
      max-width: 640px;
    }
    b{
      font-family: futura-pt-bold, sans-serif;
    }
    .overlay-video{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .overlay-button{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 99px;
    background-color: #c8b7da;
    color: white;
    font-family: futura-pt-bold, sans-serif;
    font-size: 1.6rem;
    border: none;
    cursor: pointer;
    outline: none;
    text-decoration: underline;
    &:hover{
      background-color: darken(#c8b7da, 6);
    }
  }
}