.burger-menu{
  position: fixed;
  top:25px;
  left:20px;
  z-index: 999;
  background-color: transparent;
  cursor: pointer;
  svg{
    width: 24px;
    height: 24px;
    fill: #000;
    background-color: #fff;
    padding:15px;
    border-radius: 50%;
  }
  .burger-menu-items{
    position: relative;
    z-index: 9999;
    background-color: #fff;
    padding:24px 12px;
    border-radius: 12px;
    margin:12px 0 0 0;
    display: flex;
    .burger-menu-col{
      margin: 0 12px 0 12px;
      min-width: 210px;
      h1{
        font-size: 1.2rem;
        margin: 0 0 12px 0;
      }
      .burger-menu-item{
        display: flex;
        align-items: center;
        h1{
          font-size: 1.2rem;
          margin: 9px 0;
          padding-bottom: 3px;
        }
        .burger-menu-item-square{
          width:18px;
          height:18px;
          margin-right: 9px;
          border-radius: 50%;
        }
        &:hover{
          .burger-menu-item-square{
            scale: 1.3;
          }
        }
      }
    }
  }
}