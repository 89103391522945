.section-nav{
  position: relative;
  .section-nav-item{
    cursor: pointer;
    position: absolute;
    font-family: "raleway", sans-serif;
    font-weight: 400;
    font-style: normal;
    position: relative;
    font-size: 3rem;
    color: white;
    margin:0;
    z-index: 99;
    padding:20px;
    height:66px;
    display: flex;
    align-items: center;
    width:100%;
  }
  .section-nav-wipe{
    position: fixed;
    top:50%;
    left:50%;
    width: 10px;
    height: 10px;
    z-index: 999;
    border-radius: 50%;
  }
}