.loading-screen{
  position: relative;
  display:flex;
  flex-direction: column;
  height:100vh;
  height: -webkit-fill-available;
  overflow: hidden;
  background-image: url('https://d1qk8s4yjmvpvz.cloudfront.net/ourcitychambers/loading_bg.jpg');
  background-size: cover;
  h1{
    font-family: "raleway", sans-serif;
    font-weight: 700;
    font-style: normal;
    position: relative;
    font-size: 3rem;
    color: white;
    margin:0;
    z-index: 99;
    padding:20px 0 20px 24px;
    height:66px;
    width:100%;
    display: flex;
    align-items: center;
    background-color: #c8b7da;
    display: flex;
    justify-content: space-between;
    .how-to{
      font-size: 1rem;
      margin-right: 48px;
      text-decoration: underline;
    }
  }
  h2{
    position: relative;
    font-family: "raleway", sans-serif;
    font-size: 1.3rem;
    margin-bottom: 12px;
    padding:0 24px;
    text-align: left;
    width:30%;
    z-index: 9;
  }
  img{
    position: absolute;
    bottom:88px;
  }
  .loading-wrapper{
    position: absolute;
    bottom:44px;
    left:calc(50% - 22px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
  }
  .shutter-left{
    //create a right angle triangle
    width: 0;
    height: 0;
    border-bottom: 135vh solid transparent;
    border-left: 90vw solid #d7d4d2;
    position: absolute;
    top: 0;
    right: 28%;
  }
  .shutter-right{
    //create a right angle triangle
    width: 0;
    height: 0;
    border-top: 135vh solid transparent;
    border-right: 90vw solid #d7d4d2;
    position: absolute;
    top: 0;
    left: 10%;
  }
  .did-you-know{
    position: relative;
    z-index: 2;
    position: absolute;
    bottom:160px;
    right:66px;
    width:30%;
    background-color: white;
    padding:21px;
    border:6px solid #c8b7da;
    font-size: 1.3rem;
    font-family: futura-pt-bold, sans-serif;
  }
  .enter-button{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 99px;
    background-color: #c8b7da;
    color: white;
    font-family: futura-pt-bold, sans-serif;
    font-size: 1.6rem;
    border: none;
    cursor: pointer;
    outline: none;
    text-decoration: underline;
    &:hover{
      background-color: darken(#c8b7da, 6);
    }
  }
}