.app-container{
  height: 4000vh;
  .canvas-loading{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:calc(50% - 22px);
    left: calc(50% - 22px);
  }
}