@import './sass/_all';

html{
  scroll-behavior: smooth;
}

html, body, #root {
  height: 100vh;
}

body{
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding:0;
  background-color: #d7d4d2;
}

canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  max-height: 100vh;
  max-width: 100vw;
  transform: translate(-50%, -50%);
  z-index: -1;
  display: block;
}