.section-wrapper{
  overflow: hidden;
  .animation-layer{
    position: fixed;
    left: 0;
    top: 0;
    width:100%;
    height: 100%;
    z-index: 1;
    .animation-layer-header{
      font-family: "raleway", sans-serif;
      font-weight: 700;
      font-style: normal;
      position: relative;
      font-size: 3rem;
      color: white;
      margin:0;
      z-index: 99;
      padding:20px 0 20px 99px;
      height:66px;
      display: flex;
      align-items: center;
    }
    .animation-text{
      position: relative;
      z-index: 2;
      position: absolute;
      bottom:66px;
      right:66px;
      width:50%;
      background-color: #d7d4d2;
      padding:21px;
      border-width: 6px;
      border-style: solid;
    }
    .text-box{
      position: relative;
      z-index: 2;
      position: absolute;
      bottom:66px;
      right:66px;
      width:50%;
      background-color: #d7d4d2;
      padding:21px;
      border-width: 6px;
      border-style: solid;
      font-size: 1.4rem;
    }
    .animation-layer-subheader{
      font-size: 1.3rem;
      font-family: futura-pt-bold, sans-serif;
      margin-bottom: 12px;
    }
    .animation-layer-description{
      font-size: 1.2rem;
    }  
    .shutter-left{
      //create a right angle triangle
      width: 0;
      height: 0;
      border-bottom: 135vh solid transparent;
      border-left: 90vw solid #d7d4d2;
      position: absolute;
      top: 0;
      right: 28%;
    }
    .shutter-right{
      //create a right angle triangle
      width: 0;
      height: 0;
      border-top: 135vh solid transparent;
      border-right: 90vw solid #d7d4d2;
      position: absolute;
      top: 0;
      left: 10%;
    }

    .shutter-close-left{
      //create a right angle triangle
      width: 0;
      height: 0;
      border-bottom: 135vh solid transparent;
      border-left:100vw solid #d7d4d2;
      position: absolute;
      top: 0;
      right: 0;
    }
    .shutter-close-right{
      //create a right angle triangle
      width: 0;
      height: 0;
      border-top: 135vh solid transparent;
      border-right: 100vw solid #d7d4d2;
      position: absolute;
      top: 0;
      left:0;
    }
    .green-coat-wrapper{
      position: fixed;
      bottom:-460px;
      left:-72px;
      z-index: 2;
      img{
        max-width: 50%;
      }
      .bubble {
        position: relative;
        left:330px;
        top:180px;
        font-family: sans-serif;
        font-size: 18px;
        line-height: 24px;
        width: 300px;
        background: grey;
        border-radius: 40px;
        padding: 24px;
        color: #fff;
      }
      
      .bubble-bottom-left:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 24px solid grey;
        border-right: 12px solid transparent;
        border-top: 12px solid grey;
        border-bottom: 20px solid transparent;
        left: 32px;
        bottom: -24px;
      }
    }
    .callouts-container{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      color: #fff;
      .callout-wrapper{
        position: fixed;
        width:100px;
        height:100px;
        .callout-title{
          position: absolute;
          font-size: 1.3rem;
          margin: 0 0 12px 0;
          padding: 0 0 1px 0;
          border-bottom: 3px solid #fff;
          text-shadow: 0px 0px 9px rgba(0,0,0,1);
          width: auto;
          white-space: nowrap;
          &.top-right{
            left: 99px;
            top:-25px;
          }
          &.top-left{
            left: 0px;
            top:-25px;
          }
          &.bottom-right{
            left: 99px;
            top:72px;
          }
          &.bottom-left{
            top:72px;
          }
        }
        .callout-text{
          position: absolute;
          font-size: 0.9rem;
          margin: 6px 0 12px 0;
          width: 330px;
          text-shadow: 0px 0px 12px rgba(0,0,0,1);
          &.top-right{
            left: 99px;
            top:0px;
          }
          &.top-left{
            left: 0px;
            top:0px;
            text-align: right;
          }
          &.bottom-right{
            left: 100px;
            top:100px;
          }
          &.bottom-left{
            top:100px;
            text-align: right;
          }
        }
      }
    }
    .scroll-down{
      position: absolute;
      bottom:22px;
      left:calc(50% - 100px);
      width:200px;
      height:9px;
      z-index: 99;
      animation: bounce 1s infinite;
      -webkit-animation: bounce 1s infinite;
      font-size: 1.2rem;
      text-align: center;
    }
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-3px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-3px);
    }
  }
}